<template>
	<div class="dialog customtempvetreport">
		<modal-dialog :is-open="true" title="Create Vet Report" close-text="Cancel" :actions="actions" @close="close">
			<img :src="imgUrl" />
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'

const imgUrl = require('@/router/views/RepositoryReview/tempVetReportImage.png')

const CreateVetReportDlg = {
	name: 'CreateVetReportDlg',
	components: {
		ModalDialog,
	},
	props: {
		reasonKeys: {
			type: Array,
			required: true,
		},
		warningKeys: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
	computed: {
		actions() {
			return [
				{
					type: 'button',
					emit: 'close',
					name: 'Create',
				},
			]
		},
	},
	watch: {},
	created() {
		this.imgUrl = imgUrl
	},
	methods: {
		close() {
			dlg.close(this, true, false, false)
		},
	},
}

export default CreateVetReportDlg

export function showCreateVetReportDlg() {
	return dlg.open(CreateVetReportDlg, {}, false)
}
</script>

<style lang="scss">
.customtempvetreport {
	.modal-dialog {
		max-width: 80%;
		.footer {
			padding: 16px;
		}
	}
	.modal-body {
		overflow-y: auto;
		padding: 0;
		background: var(--primary-bg);
	}

	img {
		max-width: 100%;
	}
}
</style>
