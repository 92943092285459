<template>
	<div>
		<h3>{{ heading }}</h3>
		<div style="display:flex; flex-direction:row">
			<div style="flex-grow:1">
				<p class="instructions">
					<svg-icon icon="arrow-all" fixed />
					<span class="touch-only">
						Select an image, followed by the matching view.
					</span>
					<span class="mouse-only">
						Select an image, followed by the matching view,
						<em>or</em>
						drag each image.
					</span>
				</p>
				<p class="instructions">
					Any remaining unmatched images will not be submitted.
				</p>
			</div>
			<div style="flex-shrink:1;">
				<p v-if="!disabled" class="instructions">
					<ast-button type="success" :disabled="disabled" @click.native="selectFromExisting">
						<span class="plus">+</span>
						Add a previously uploaded study
					</ast-button>
				</p>
			</div>
		</div>
		<div class="matching-panes">
			<div class="your-images">
				<h4>Your images</h4>
				<div class="thumbnail-pane">
					<div
						v-for="thumbnail in unmatchedStudyImages"
						:key="thumbnail.imageId"
						class="thumbnail"
						:class="getThumbnailClass(thumbnail)"
						draggable="true"
						:style="{
							'background-image': `url(${getThumbnailUrl(thumbnail)})`,
						}"
						@click="selectThumbnailAction(thumbnail)"
						@dragstart="onDragStart(thumbnail, $event)"
					>
						<div class="thumbnail-text">
							<span>
								{{ thumbnail.name }}
								{{ thumbnail.acquisitionTime | localizeDate({ forceUTC: false }) }}
							</span>
						</div>
						<div v-if="thumbnail.status === 'Rejected'" class="rejected"><span>Rejected</span></div>
						<!-- This needs to be changed on next story -->
					</div>
					<div v-if="disabled" class="cover"> </div>
				</div>
				<a @click.prevent="addRemainingAsExtraImages">
					Add Remaining as Extra Images
				</a>
			</div>
			<div class="submission-images">
				<h4>Images for submission</h4>
				<div class="thumbnail-pane">
					<div
						v-for="(thumbnailTemplate, index) in reportImages"
						:key="thumbnailTemplate.reportTemplateImageViewId || thumbnailTemplate.imageId"
						class="thumbnail"
						:class="getThumbnailClass(thumbnailTemplate)"
						:style="{
							'background-image': `url(${getThumbnailUrl(thumbnailTemplate)})`,
						}"
						@click="onImageViewClick(thumbnailTemplate)"
						@dragover.prevent="onImageViewDragOver"
						@dragleave.prevent="onImageViewDragLeave"
						@drop.prevent="onImageViewDrop(thumbnailTemplate, $event)"
					>
						<div v-if="!thumbnailTemplate.imageId" class="dim-overlay"></div>
						<div class="thumbnail-text">
							<p>{{ thumbnailTemplate.templateName || thumbnailTemplate.extraReason }}</p>
							<div class="thumbnail-status">
								<p v-if="thumbnailTemplate.imageId" class="is-success">
									<svg-icon icon="check-circle" />
								</p>
								<p v-if="thumbnailTemplate.isRequired && !thumbnailTemplate.imageId" class="is-danger">
									Required
								</p>
								<p v-if="!thumbnailTemplate.isRequired && !thumbnailTemplate.imageId" class="is-warning">
									Optional
								</p>
							</div>
						</div>
						<svg-icon
							v-if="thumbnailTemplate.imageId"
							icon="close"
							class="clear-image"
							@click.native="unassignImage(index)"
						/>
					</div>
					<div
						class="thumbnail"
						:class="getThumbnailClass({ isExtra: true })"
						@click="onImageViewClick({ isExtra: true })"
						@dragover.prevent="onImageViewDragOver"
						@dragleave.prevent="onImageViewDragLeave"
						@drop.prevent="onImageViewDrop({ isExtra: true }, $event)"
					>
						<div class="dim-overlay"></div>
						<div class="thumbnail-text">
							<p>Extra Image</p>
						</div>
					</div>
					<div v-if="disabled" class="cover"> </div>
				</div>
				<a @click.prevent="reportImagesNotMatching">
					Images not matching?
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { openPromptDlg } from '@dialogs/TextPromptDlg.vue'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { findThumbnailUrl } from '@utils/urlUtility'
import { showAlert } from '@dialogs/MessageDlg.vue'
import salesService from '@services/salesService'
import workflow from '@services/workflow'
import { mapActions, mapGetters } from 'vuex'
import AstButton from '@components/Button'

export default {
	name: 'TeleconsultationRequestMatchImages',
	components: {
		AstButton,
	},
	props: {
		disabled: Boolean,
	},
	data() {
		return {
			hasServerMatches: false,
			salesService,
		}
	},
	computed: {
		allowAttachmentOnly() {
			return this.salesService.saleEntry.allowAttachmentOnly
		},
		...mapGetters(['reportImages', 'studyImages', 'selectedStudyImage', 'consultantId', 'saleId', 'studyIds']),
		unmatchedStudyImages() {
			return this.studyImages.filter(thumbnail => {
				return !this.reportImages.some(image => image.imageId === thumbnail.imageId)
			})
		},
		heading() {
			// TODO: Check how to do this on the BE
			if (this.hasServerMatches) {
				return (
					'Keystone has matched some of your submitted images to those accepted ' +
					'for this sale. Please review these matches, and match any additional ' +
					'images at this time.'
				)
			} else {
				return 'Please match your uploaded images to those accepted by this sale.'
			}
		},
	},
	watch: {
		reportImages: {
			handler(reportImages) {
				const isComplete = !reportImages.some(image => image.isRequired && !image.imageId)
				workflow.canGoNext = isComplete
			},
			immediate: true,
		},
	},
	async mounted() {
		await this.loadData(this.$route.params['id'])
	},
	destroyed() {
		this.clearInitialStateReviewSubmissionAction()
	},
	methods: {
		...mapActions([
			'getReportImagesAction',
			'getReviewSubmissionDetailsAction',
			'unassignImageAction',
			'selectThumbnailAction',
			'selectReportTemplateImageViewIdAction',
			'unassignTemplateAction',
			'addAsExtraImageAction',
			'addStudyToReviewSubmissionAction',
			'clearInitialStateReviewSubmissionAction',
		]),
		async selectFromExisting() {
			const openDialog = openStudiesDlg
			const study = await openDialog()
			if (study) {
				this.addStudyToReviewSubmissionAction({ studyId: study.studyId })
			}
		},
		getThumbnailClass(thumbnail) {
			const classes = []
			const isUnmatched = thumbnail.isExtra || (thumbnail.reportTemplateImageViewId && !thumbnail.imageId)
			if (isUnmatched) classes.push('is-unmatched')
			if (!isUnmatched && thumbnail.reportTemplateImageViewId) classes.push('is-matched')
			if (isUnmatched && this.selectedStudyImage) classes.push('is-target')
			if (thumbnail.isSelected) classes.push('is-selected')
			return classes
		},
		getThumbnailUrl(thumbnail) {
			// study thumbnail (on left)
			if (thumbnail.imageId && thumbnail.storageLocation) return findThumbnailUrl(this.setThumbnailData(thumbnail))
			// matched study thumbnail (on right)
			if (thumbnail.imageId && !thumbnail.thumbnail) {
				const studyImage = this.studyImages.find(({ imageId }) => imageId === thumbnail.imageId)
				return findThumbnailUrl(this.setThumbnailData(studyImage))
			}
			// image view thumbnail (on right)
			return `data:image;base64,${thumbnail.thumbnail}`
		},
		onDragStart(thumbnail, event) {
			thumbnail = { ...thumbnail, isSelected: false }
			this.selectThumbnailAction({ thumbnail })
			event.dataTransfer.setData('text', '') // not used, but required for Firefox
			if (event._dndHandle && event.dataTransfer.setDragImage) {
				const ghostImage = event.target.closest('[draggable="true"]')
				event.dataTransfer.setDragImage(ghostImage, 0, 0)
			}
		},
		onImageViewClick(imageView) {
			if (this.selectedStudyImage) this.assignImageToView(imageView)
		},
		onImageViewDragOver(event) {
			event.dataTransfer.dropEffect = 'move'
			if (!event.target || !event.target.classList) return
			if (event.target.classList.contains('thumbnail')) event.target.classList.add('is-drag-over')
		},
		onImageViewDragLeave(event) {
			event.dataTransfer.dropEffect = 'move'
			if (!event.target || !event.target.classList) return
			if (event.target.classList.contains('thumbnail')) event.target.classList.remove('is-drag-over')
		},
		onImageViewDrop(imageView, event) {
			event.target.classList.remove('is-drag-over')
			this.assignImageToView({ reportTemplateImageViewId: imageView.id })
		},
		async assignImageToView({ reportTemplateImageViewId, isExtra }) {
			this.selectReportTemplateImageViewIdAction({ reportTemplateImageViewId })
		},
		async addRemainingAsExtraImages() {
			this.addAsExtraImageAction()
		},
		unassignImage(index) {
			this.unassignTemplateAction(index)
		},
		async reportImagesNotMatching() {
			try {
				await salesService.imagesNotMatching({
					consultantId: this.consultantId,
					saleId: this.saleId,
					studyIds: this.studyIds,
				})
			} finally {
				showAlert(
					`Asteris personnel have been notified of the problem. ` +
						`Multiple submissions of images from the same modality device will not ` +
						`increase the priority of this issue.  Thank you for your patience.`
				)
			}
		},
		//
		async loadData(reviewSubmissionId) {
			await this.loadReviewSubmissionDetails(reviewSubmissionId)
			await this.loadImageTemplates()
		},
		async loadImageTemplates() {
			await this.getReportImagesAction()
		},
		async loadReviewSubmissionDetails(reviewSubmissionId) {
			await this.getReviewSubmissionDetailsAction(reviewSubmissionId)
		},
		setThumbnailData(thumbnail) {
			const thumbNailData = {
				imageId: thumbnail?.imageId,
				storageLocation: {
					endpointBaseUrl: thumbnail?.endpointBaseUrl,
					clinicCode: thumbnail?.clinicCode,
				},
			}
			return thumbNailData
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.instructions {
	padding-top: 16px;
	& + .instructions {
		padding-top: 8px;
	}
}
.touch-only {
	display: none;
	@media (hover: none) {
		display: initial;
	}
}
.mouse-only {
	display: none;
	@media not all and (hover: none) {
		display: initial;
	}
}
.matching-panes {
	display: flex;
	margin: 0 -16px;
	& > * {
		padding: 16px;
		min-width: 50%;
		width: 50%;
		height: 100%;
	}
	@media (max-width: $mqLarge) {
		flex-direction: column;
		& > * {
			width: 100%;
		}
	}
	a {
		display: block;
		margin-top: 8px;
		user-select: none;
		cursor: pointer;
		font-weight: 400;
		&:hover {
			text-decoration: underline;
		}
	}
}
.thumbnail-pane {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	height: 60vh;
	max-height: 60vh;
	overflow-y: auto;
	margin-top: 8px;
	padding: 2px;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	@media (max-width: $mqLarge) {
		height: 28vh;
		max-height: 28vh;
	}
}
.thumbnail {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 125px;
	height: 125px;
	background-size: contain;
	background-color: black;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	margin: 4px;
	user-select: none;
	color: #fff;
	padding: 3px;
	text-shadow: 1px 1px 2px #000;
	opacity: 0.9;
	cursor: pointer;
	.icon {
		filter: drop-shadow(1px 1px 2px #000);
	}
	&:hover {
		opacity: 1;
	}
	& > * {
		z-index: 1;
	}
	.dim-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--card-bg);
		opacity: 0.75;
		z-index: 0;
		pointer-events: none; // prevent firing thumbnail dragleave event
	}
	&.is-matched {
		cursor: default;
		&:hover {
			opacity: 0.9;
		}
	}
	&.is-unmatched {
		color: var(--primary-label);
		background: var(--checkbox-unchecked-border);
		text-shadow: 1px 1px 2px var(--checkbox-unchecked-border);
		cursor: default;
		&:hover {
			opacity: 0.9;
		}
	}
	&.is-selected {
		border: 3px solid var(--checkbox-checked-border);
		padding: 0;
		.rejected {
			padding: 13px;
		}
	}
	&.is-selected,
	&.is-target {
		cursor: pointer;
	}
	&.is-target .dim-overlay {
		border: 2px solid var(--checkbox-checked-border);
	}
	&.is-target.is-drag-over .dim-overlay,
	&.is-target:hover .dim-overlay {
		border-bottom-width: 4px;
		opacity: 0.9;
	}
}
.clear-image {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 30px;
	opacity: 0.85;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
}
.thumbnail-text {
	text-align: center;
	font-weight: 700;
	font-size: 0.9em;
	word-break: break-word;
	width: 100%;
	padding: 4px;
	pointer-events: none; // prevent firing thumbnail dragleave event
	.thumbnail-status {
		font-size: 0.9em;
		padding-top: 2px;
	}
}
.rejected {
	position: absolute;
	background: rgba(255, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	padding: 16px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.cover {
	position: absolute;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: not-allowed;
}
</style>
