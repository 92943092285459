<template>
	<div class="page-wrapper">
		<!-- WRAPPER -->
		<div class="list-scroll">
			<h3>Submission for {{ translate('consignerStudy') }} {{ hipNumber }} in Sale {{ saleName }} </h3>
			<h3>Status: {{ submissionStatus }}</h3>
			<br />
			<match-images :disabled="false" @openImage="openViewer" />

			<h3>Attachments</h3>
			<div class="attachments">
				<div class="attachments">
					<single-upload-form
						v-for="attachment in attachmentsTemplates"
						:key="attachment.id"
						class="attachment"
						context="teleconsultation-request"
						:allowed-extensions="['pdf', 'mp4', 'jpg', 'png']"
						:description="attachment.templateName"
						:image-id="attachment.imageId"
						:image-view-id="''"
						:report-template-image-view-id="attachment.id"
						:is-required="attachment.isRequired"
						:patient-id="patientId"
						@study-complete="addUploadedBatch"
						@remove-batch="removeBatch"
					/>
				</div>
			</div>
		</div>
		<ast-toolbar class="toolbar-header-secondary">
			<div class="filler"></div>
			<div class="right">
				<template>
					<ast-button :disabled="submissionStatus === 'Rejected'" @click="sendReviewSubmission('reject')"
						>Reject</ast-button
					>
					<ast-button :disabled="!isApproveAllowed" @click="sendReviewSubmission('approve')">Approve</ast-button>
					<ast-button :disabled="!isApproveAllowed" @click="sendReviewSubmission('approveSubmit')"
						>Approve and Submit</ast-button
					>
				</template>
			</div>
			<ast-toolbar-button icon="help-circle" @click.native="showHelp" />
		</ast-toolbar>
		<modal-dialog v-if="showModal" title="Why can't I submit?" @close="showModal = false">
			<p>This submission is locked until the following items are completed:</p>
			<ul>
				<li>Block Item 1</li>
				<li>Blocking thing number 2</li>
				<li>Blocking thing number 3 that's longer</li>
			</ul>
		</modal-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import AstButton from '@components/Button'
import AstToolbar from '@components/Toolbar'
import AstToolbarButton from '@components/ToolbarButton'
import ModalDialog from '@components/ModalDialog'
import service from '@services/salesService'
import SingleUploadForm from '@/components/SingleUploadForm.vue'
import { openPromptDlg } from '@/dialogs/TextPromptDlg.vue'
import { showCreateVetReportDlg } from './ReviewVetReportDialog.vue'
import MatchImages from './ReviewMatchImages.vue'
import { uploadData } from '@services/uploads'

export default {
	name: 'RepositoryReviewDetails',
	components: {
		AstButton,
		AstToolbar,
		AstToolbarButton,
		MatchImages,
		SingleUploadForm,
		// eslint-disable-next-line vue/no-unused-components
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile'),
		// eslint-disable-next-line vue/no-unused-components
		UploadItemBatch: () => import(/* webpackChunkName: "componentUploadItemBatch" */ '@components/UploadItemBatch'),

		ModalDialog,
	},
	props: {
		id: String,
		noImages: Boolean,
	},
	data() {
		return {
			showModal: false,
			isApproveAllowed: false,
			batchValidationDebounce: undefined,
			haveImagesFromStudy: false,
			imageDrop: undefined,
			uploadedStudyIds: [],
			uploadedBatchIds: [],
			dicomMatches: {},
			uploadData,
		}
	},
	computed: {
		...mapState({}),
		...mapGetters([
			'submissionStatus',
			'reportImages',
			'attachmentsTemplates',
			'patientId',
			'hipNumber',
			'saleName',
			'saleId',
		]),
	},
	watch: {
		reportImages() {
			this.checkIsApproveAllowed()
		},
		attachmentsTemplates() {
			this.checkIsApproveAllowed()
		},
	},
	mounted() {},
	destroyed() {
		this.uploadData.clear()
	},
	methods: {
		...mapMutations({}),
		...mapActions(['sendReviewSubmissionAction', 'uploadAttachmentAction', 'deleteAttachmentAction']),
		checkIsApproveAllowed() {
			const reportedImages = this.reportImages.filter(report => report.isRequired === true)
			const attachmentsTemplates = this.attachmentsTemplates.filter(template => template.isRequired === true)

			this.isApproveAllowed = false

			if (reportedImages.length) {
				const hasRequiredNotMatched = reportedImages.filter(report => {
					if (report.hasOwnProperty('imageId') && report.imageId) {
						return report
					}
				})

				this.isApproveAllowed = reportedImages.length === hasRequiredNotMatched.length
			}

			if (attachmentsTemplates.length) {
				const hasRequiredNotMatchedAttachments = attachmentsTemplates.filter(template => {
					if (template.hasOwnProperty('imageId') && template.imageId) {
						return template
					}
				})
				this.isApproveAllowed =
					this.isApproveAllowed && attachmentsTemplates.length === hasRequiredNotMatchedAttachments.length
			}
		},
		async sendReviewSubmission(type) {
			try {
				if (type === 'approve') {
					await this.sendReviewSubmissionAction({ type })
					this.$router.go(-1)
				} else if (type === 'reject') {
					const reason = await openPromptDlg({
						buttonLabel: 'Reject',
						title: `Reject All Images`,
						prompt: 'Provide a reason for rejecting all images',
						requireInput: true,
					})
					if (reason) {
						await this.sendReviewSubmissionAction({ type, reason })
						this.$router.go(-1)
					}
				} else if (type === 'approveSubmit') {
					await this.sendReviewSubmissionAction({ type })
					this.$router.go(-1)
				}
			} catch (e) {
				console.log(`Something happened on the review submission, type:${type}, error${e}`)
			}
		},
		showHelp() {
			this.showModal = true
		},
		createVetReport() {
			showCreateVetReportDlg()
		},
		openViewer(imageId) {
			const { href } = this.$router.resolve('/viewer/review-studies/' + imageId)
			window.open(href, 'TEMP', 'resizable,scrollbars,status')
		},
		async addUploadedBatch({ batchId, studyId, imageId, reportTemplateImageViewId }) {
			if (batchId && !this.uploadedBatchIds.includes(batchId)) {
				this.uploadedBatchIds.push(batchId)
			}
			if (studyId && !this.uploadedStudyIds.includes(studyId)) {
				this.uploadedStudyIds.push(studyId)
			}
			if (imageId && reportTemplateImageViewId) {
				const attachment = this.attachmentsTemplates.find(
					attachment => attachment.reportTemplateImageViewId === reportTemplateImageViewId
				)
				this.uploadAttachmentAction({ studyId, imageId, reportTemplateImageViewId })
				if (attachment) attachment.imageId = imageId
			}
		},
		async removeBatch({ imageIds, batchId }) {
			await uploadData.stopUploads({ batchId })

			if (imageIds && imageIds[0]) {
				await this.deleteAttachmentAction(imageIds[0])
			}

			this.uploadedStudyIds = []
			this.uploadedBatchIds = []
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.filler {
	flex-grow: 1;
}
.right {
	padding: 6px;
	> * + * {
		margin-left: 6px;
	}
}
.attachments {
	margin: 0 -16px;
	display: flex;
	padding-left: 16px;
	flex-wrap: wrap;
	.attachment {
		margin: 16px;
	}
}
</style>
